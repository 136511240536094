<script setup lang="ts">
import type { KippieOrder } from "@shopware-pwa/types";
import type { LineItem, Promotion } from "@shopware-pwa/types";
import { formatPrice } from "~/helpers/formatters";
import { ShippingMethodSidebar } from "#components";

const shippingMethodSidebar = ref<InstanceType<typeof ShippingMethodSidebar>>();

defineProps<{
	order?: KippieOrder;
}>();

const { cartItems, totalPrice, shippingTotal, subtotal } = useShopwareCart();
const { selectedShippingMethod } = useShopwareSession();

const isPromotion = (item: LineItem): item is LineItem & { payload: Promotion } => {
	return "promotionId" in item.payload;
};

const discount = computed(() => {
	return (
		cartItems.value?.reduce((acc, item) => {
			if (isPromotion(item)) {
				return acc + (item.price?.totalPrice || 0);
			}

			return acc;
		}, 0) || 0
	);
});

const selectedHomeDelivery = computed(() => {
	return selectedShippingMethod.value?.type === "delivery";
});
</script>

<template>
	<div class="flex flex-col gap-2">
		<div class="flex justify-between text-sm">
			<span class="">Subtotaal</span>
			<span class="">{{ formatPrice(order ? order.amountTotal - order.shippingTotal : subtotal) }}</span>
		</div>
		<div class="flex justify-between text-sm" v-if="discount < 0 && !order">
			<span class="">Korting</span>
			<span class="">{{ formatPrice(discount) }}</span>
		</div>
		<div class="flex justify-between text-sm">
			<span>
				Verzendkosten<br />
				<KippieLink
					class="text-xs underline"
					@click.prevent="shippingMethodSidebar?.open()"
					to="#"
					v-if="!order && selectedHomeDelivery && shippingTotal > 0"
				>
					Toch liever gratis afhalen?
				</KippieLink>
			</span>
			<span class="">{{ formatPrice(order ? order.shippingTotal : shippingTotal) }}</span>
		</div>
		<div class="border-b border-brown w-full"></div>
		<div class="flex justify-between font-bold">
			<span class="">Totaal</span>
			<span class="">{{ formatPrice(order ? order.amountTotal : totalPrice) }}</span>
		</div>
		<ShippingMethodSidebar ref="shippingMethodSidebar" />
	</div>
</template>
